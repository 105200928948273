import * as React from "react"
import styled from "styled-components"
import { FC, useState } from "react"
import LPBanner6 from "../components/banner/LPBanner6"
import LPOptionsFeatureBlock, { OptionType } from "../components/general/LPOptionsFeatureBlock"
import LPImageFeatureBlock from "../components/general/LPImageFeatureBlock"
import LPEnquiryFormCTABlock from "../components/cta/LPEnquiryFormCTABlock"
import LPReviewsAndTrustBlock from "../components/reviews/LPReviewsAndTrustBlock"
import EnquiryFormModal from "../components/lead-capture/enquiry-form/EnquiryFormModal"
import LPCorporateQuotesBlock from "../components/reviews/LPCorporateQuotesBlock"
import LPReviewsRatingBlock from "../components/reviews/LPReviewsRatingBlock"
import { navigate } from "gatsby"

const Container = styled.div``

const items = [
  "Themed hampers for your employees and clients",
  "Unique desk-drops and corporate gift ideas",
  "Same-day response tailored to your brief and budget",
]

const getOptions = (onModal: () => void): OptionType[] => [
  {
    title: "Gift Boxes & Hampers",
    description:
      "Themed hampers for your employees and clients, including onboarding, 'get well soon' and Easter boxes.",
    imageSrc: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/1-1-1.png",
    primaryOnClick: () => navigate("/product-category/hampers-and-gift-boxes/"),
    primaryLabel: "Shop Now",
    secondaryOnClick: () => navigate("/corporate-hampers-gift-boxes"),
    secondaryLabel: "Find Out More",
  },
  {
    title: "Branded Merchandise",
    description:
      "Corporate merch that reflects your brand and your values, from sustainable coffee cups to hoodies and even office plants.",
    imageSrc: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/2-2.png",
    primaryOnClick: () => navigate("/product-category/branded-merchandise"),
    primaryLabel: "Shop Now",
    secondaryOnClick: () => navigate("/sustainable-branded-merchandise"),
    secondaryLabel: "Find Out More",
  },

  {
    title: "Individual Gifts",
    description:
      "Individual gifts like wine, cosmetic gift sets and even hot water bottles. Because why not?",
    imageSrc: "https://data.socialsupermarket.org/wp-content/uploads/2018/10/3-3.png",
    primaryOnClick: () => onModal(),
    primaryLabel: "Get in touch",
  },
]

interface Props {}

const CorporateGiftingLP: FC<Props> = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  return (
    <div>
      <LPBanner6
        title={"Send corporate gifts that do good"}
        checklistItems={items}
        actionLabel={"Get In Touch"}
        onAction={() => setShowModal(true)}
      />
      <LPOptionsFeatureBlock options={getOptions(() => setShowModal(true))} />
      <LPReviewsRatingBlock />
      <LPImageFeatureBlock
        title={<>Give your recipients the choice</>}
        description={
          "Our gifting platform allows recipients to select their preferred gift and arrange delivery."
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Untitled-design-6.png"
        }
        colorWay={2}
        to={"/team-gifting-platform"}
      />

      <LPImageFeatureBlock
        title={<>Vote with your wallet</>}
        description={
          <div>
            Every product you buy benefits an environmental or social cause. Our team helps you find
            products that align directly with you ESG commitment quickly and easily.
          </div>
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Bread-c-Tom-Moggach-scaled.jpeg"
        }
        colorWay={0}
        invert
      />
      <LPImageFeatureBlock
        title={<>Impact reporting</>}
        description={
          "Our impact reporting and storytelling tools help you understand your impact and improve stakeholder engagement."
        }
        imageUrl={
          "https://data.socialsupermarket.org/wp-content/uploads/2018/10/Untitled-design-4.png"
        }
        colorWay={1}
      />

      <LPEnquiryFormCTABlock
        onClick={() => setShowModal(true)}
        description={
          "Speak to one of our team and we would be pleased to get you set up or answer any questions you may have."
        }
      />
      <LPCorporateQuotesBlock />
      <EnquiryFormModal
        toggle={setShowModal}
        show={showModal}
        source={"Inbound Enquiry - Gifting"}
        name={"GiftingEnquiryForm"}
      />
    </div>
  )
}

export default CorporateGiftingLP
