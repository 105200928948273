import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import ReviewsIoTextBanner from "../../../../../../components/home/reviews-io/ReviewsIoTextBanner"

const Container = styled.div`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : "white")};
  padding: 0px;
  padding-bottom: 40px;
`
const Body = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
`

interface Props {
  backgroundColor?: string
}

const LPReviewsRatingBlock: FC<Props> = ({ backgroundColor }) => {
  return (
    <Container backgroundColor={backgroundColor}>
      <Body>
        <ReviewsIoTextBanner />
      </Body>
    </Container>
  )
}

export default LPReviewsRatingBlock
