import React, { Component } from "react"
import styled from "styled-components"
import "./reviewio"

export const ReviewsIoContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 50px 20px 30px;
  display: flex;
  align-items: center;

  iframe {
    margin-bottom: 0;
  }
`

const CarouselWidget = styled.div`
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
`

class ReviewsIoTextBanner extends Component {
  componentDidMount() {
    richSnippetReviewsWidgets("text-banner-widget", {
      store: "social-supermarket",
      widgetName: "text-banner",
      primaryClr: "#f47e27",
      neutralClr: "#f4f4f4",
      reviewTextClr: "#2f2f2f",
      ratingTextClr: "#2f2f2f",
      layout: "fullWidth",
      numReviews: 21,
    })
  }

  render() {
    return (
      <ReviewsIoContainer className="">
        <CarouselWidget id="text-banner-widget" />
      </ReviewsIoContainer>
    )
  }
}

export default ReviewsIoTextBanner
